import Layout from 'components/Layout/Layout'
import { LayoutTextSection } from 'components/Layout/LayoutTextSection'
import { Link } from 'components/Link/Link'
import { ParagraphHeading } from 'components/ParagraphHeading/ParagraphHeading'
import SEO from 'components/SEO/SEO'
import { SITE_HOSTNAME } from 'constants/texts/company'
import { CONTACT_EMAIL_ADDRESS } from 'constants/texts/contact'
import { Text } from 'ui/common'

const Terms: React.FC = () => (
  <Layout>
    <SEO title="Regulamin" />
    <LayoutTextSection mt="xl">
      <h1>Regulamin GLAMO.PL</h1>
      <p>
        <em>Regulamin - Warunki świadczenia usług - GLAMO.PL</em>
      </p>
      <p>
        Niniejszy regulamin określa warunki korzystania z wypożyczalni Glamo.pl.
        <br />
        Korzystanie z wypożyczalni oznacza akceptację niniejszego regulaminu w
        całości.
      </p>
      <p>
        Podmiotem udostępniającym artykuły do wypożyczenia jest:
        <p style={{ paddingLeft: '32px' }}>
          Pracownia Florystyczna Cuda Kwiatki Karolina Struzik
          <br />
          ul. Kazimierzowska 18a 05-807 Owczarnia
          <br />
          NIP:534-238-16-63
          <br />
          REGON: 365805643
        </p>
        zwany dalej Glamo.pl
      </p>
      <p>
        Podmiot wypożyczający - klient, korzystający z usług Glamo.pl, zwany
        jest dalej Zamawiającym.
      </p>

      <ParagraphHeading>Warunki korzystania</ParagraphHeading>
      <ol>
        <li>
          Wypożyczenie odbywa się poprzez sieć Internet w formie Umowy najmu
          zawieranej na odległość, pomiędzy Zamawiającym a Glamo.pl. Przez Umowę
          najmu Glamo.pl zobowiązuje się przekazać Zamawiającemu określone
          przedmioty do używania przez czas oznaczony, a Zamawiający zobowiązuje
          się wpłacić Glamo.pl umówioną opłatę za wypożyczenie i zwrócić rzeczy
          w stanie niepogorszonym w terminie oznaczonym w Umowie najmu.
        </li>
        <li>
          Najem ma charakter otwarty dla Użytkowników strony internetowej.
          Wystawione towary są dostępne dla Użytkowników bez ograniczeń w ramach
          dostępnych stanów magazynowych. Podawane przez Glamo.pl ceny są
          informacjami jawnymi, podawanymi do wiadomości publicznej na witrynie
          internetowej przy każdym produkcie, którego dotyczą. Podane ceny są
          cenami brutto (zawierają podatek VAT).
        </li>
      </ol>

      <ParagraphHeading>Wysyłka i odbiór towarów</ParagraphHeading>
      <ol>
        <li>
          Zamawiający może samodzielnie odebrać od Wynajmującego wypożyczane
          artykuły pod adresem: ul. Kazimierzowska 18a, 05-807 Owczarnia, nie
          wcześniej niż 2 dni przed datą wydarzenia, chyba że obie strony
          uzgodniły inaczej.
        </li>
        <li>
          W przypadku braku możliwości odbioru osobistego istnieje możliwość
          dostarczenia zamawianych artykułów kurierem za dodatkową opłatą.
          Jednak nie wszystkie rekwizyty możemy przesłać za pośrednictwem
          kuriera. Przy każdym produkcie znajduje się informacja o dostępnych
          sposobach dostawy. Istnieje także możliwość dostawy transportem
          własnym Glamo.pl za dodatkową opłatą, ustalaną indywidualnie na
          podstawie odległości miejsca dostawy od magazynu Glamo.pl. Opcja
          możliwa przy zamówieniu za kwotę minimum 200zł. Wysyłka nastąpi nie
          później niż 2 dni przed datą wydarzenia, lecz nie wcześniej niż 5 dni
          przed datą wydarzenia. O wysyłce kurierskiej Zamawiający zostanie
          poinformowany drogą elektroniczną. Dogodna data transportu Glamo.pl
          zostanie ustalona pomiędzy Glamo.pl a Zamawiającym najpóźniej na 10
          dni przed wydarzeniem.
        </li>
        <li>
          Zmiany ilości zamawianych produktów względem Umowy można dokonać
          maksymalnie na 10 dni przed wysyłką o maksymalnie +/-15% (w górę -
          wyłącznie po weryfikacji dostępności towaru).
        </li>
      </ol>

      <ParagraphHeading>
        Obowiązki i odpowiedzialność zamawiającego i wynajmującego
      </ParagraphHeading>
      <ol>
        <li>
          <p>
            Wypożyczane artykuły zostaną zwrócone do magazynu Glamo.pl w takim
            stanie, w jakim były dostarczone, czyli właściwie wyczyszczone,
            dokładnie spakowane i dostarczone w oryginalnych opakowaniach
            osobiście lub poprzez firmę kurierską do 3 dni roboczych po dacie
            wydarzenia.
          </p>
          <p>
            W przypadku przekroczenia podanego terminu zwrotu wypożyczonych
            artykułów z kaucji zostanie pobrana kara w wysokości podanej w
            Umowie.
          </p>
        </li>
        <li>
          Glamo.pl nie ponosi odpowiedzialności za opóźnienia firm
          transportowych i ewentualne uszkodzenia artykułów podczas przesyłki.
          Odpowiedzialność spoczywa na przewoźniku.
        </li>
        <li>
          <p>
            Każdorazowo należy otworzyć i sprawdzić zawartość przesyłki w
            obecności kuriera, zwłaszcza jeśli paczka ma widoczne ślady
            naruszenia. W razie stwierdzenia jakichkolwiek uszkodzeń naszych
            dekoracji, należy spisać protokół oraz zrobić zdjęcia i jak
            najszybciej skontaktować się z nami.
          </p>
          <p>
            Odpowiedzialność za uszkodzenie paczki w transporcie spoczywa na
            firmie kurierskiej. Wszystkie paczki są ubezpieczone zgodnie z
            wartością. Ewentualne stwierdzone uszkodzenia lub braki w
            przedmiotach muszą być zgłaszane przez Zamawiającego niezwłocznie po
            odbiorze przesyłki. Tego typu uwagi zgłoszone po terminie
            uroczystości nie będą stanowić podstaw do zgłoszenia reklamacji.
          </p>
        </li>
        <li>
          Zamawiający zobowiązuje się zachować szczególną troskę o wynajmowane
          elementy Wynajmującego przed kradzieżą̨, uszkodzeniem, zniszczeniem i
          innymi zdarzeniami losowymi i/lub spowodowanymi przez osoby trzecie. W
          przypadku powstania jakiejkolwiek szkody w elementach Zamawiający
          zobowiązuje się do uiszczenia kwoty pieniężnej pozwalającej na
          odtworzenie poniesionych strat przez Wynajmującego.
        </li>
        <li>
          W przypadku dokonania przez Zamawiającego uszkodzeń przedmiotu Umowy w
          stopniu, który uniemożliwi dalsze wykorzystywanie wypożyczanych
          artykułów, potrącona zostanie kwota z kaucji równowarta wartości
          zakupu przedmiotu. Do uszkodzeń w przypadku produktów tekstylnych
          zaliczane są również plamy z wosku i czerwonego wina, których nie da
          się usunąć poprzez standardowe czyszczenie. Koszt ich usunięcia
          zostanie potrącony z kaucji.
        </li>
        <li>
          Strony uzgadniają, że Wynajmujący nie ponosi odpowiedzialności za
          szkody Zamawiającego i osób trzecich (w tym firm kurierskich)
          spowodowane niezgodnym z przeznaczeniem użytkowaniem, transportem,
          składowaniem, przechowywaniem. Wynajmujący nie ponosi
          odpowiedzialności również za utracone korzyści i zyski, które powstały
          w wyniku braku możliwości wykorzystania wypożyczonych elementów z
          jakiegokolwiek powodu i przyczyn.
        </li>
        <li>
          W przypadku deklaracji zwrotu czystych produktów, muszą one zostać
          odesłane czyste, (a materiały także wyprasowane) w stopniu nadającym
          się do ponownego użycia. Glamo.pl zastrzega sobie prawo do weryfikacji
          czystości. Jeśli produkty zostaną uznane za wymagające ponownego
          wyczyszczenia, koszt czyszczenia zostanie potrącony z kaucji. Za
          czyste rozumie się dekoracje umyte (bez śladów jedzenia, wosku,
          tłuszczu itp.) oraz wypolerowane (szkło – bez śladów odcisków palców).
        </li>
        <li>
          W przypadku niezwrócenia wypożyczonych artykułów, wszczęte zostanie
          postępowanie sądowe.
        </li>
        <li>
          W przypadku zaistnienia okoliczności, przez które Glamo.pl nie będzie
          w stanie dostarczyć przedmiotów zawartych w Umowie (częściowo albo w
          całości np. poprzez uszkodzenie towarów przez poprzedniego najemcę),
          Glamo.pl zobowiązuje się przedstawić rozwiązanie alternatywne
          (produkty zastępcze, częściową realizację, własny transport itp. w
          zależności od zaistniałej sytuacji). Zamawiający ma prawo odmówić
          przyjęcia alternatywnego rozwiązania. W takim przypadku wpłacony przez
          Zamawiającego zadatek zostaje mu zwrócony zgodnie z zasadami Kodeksu
          Cywilnego.
        </li>
      </ol>

      <ParagraphHeading>Opłaty i kaucja</ParagraphHeading>
      <ol>
        <li>
          <p>
            Ogólną wartość za wynajem produktów oraz całą usługę określa
            Glamo.pl na podstawie udostępnionej oferty i zamówienia
            Zamawiającego. Opłaty za wynajem Towaru pobierane są z góry za cały
            okres wynajmu, zgodnie ze złożonym za pośrednictwem formularza
            kontaktowego zamówieniem.
          </p>
          <p>
            Opłaty za wynajem towaru liczone są za pełną dobę. Umowa najmu
            zawarta jest tylko na pełne dni.
          </p>
        </li>
        <li>
          Warunkiem rezerwacji przedmiotów jest wpłacenie zadatku w wysokości
          podanej w Umowie wypożyczenia.
        </li>
        <li>
          W przypadku wyboru sposobu dostawy przesyłką kurierską lub transportem
          Glamo.pl, jej koszt doliczany jest do zamówienia.
        </li>
        <li>
          Koszt przesyłki zwrotnej do Glamo.pl pokrywa Zamawiający, chyba że
          strony ustalą inaczej.
        </li>
        <li>
          Zamawiający otrzymuje Umowę wypożyczenia w terminie do 3 dni roboczych
          po złożeniu zamówienia. Na wpłatę zadatku, zgodnie z Umową,
          Zamawiający ma 3 dni robocze od jej otrzymania. Po tym czasie
          rezerwacja przepada, a Umowa traci ważność.
        </li>
        <li>
          Glamo.pl pobiera przed wysyłką kaucję zwrotną w wysokości 50% wartości
          zamówienia. W przypadku uszkodzenia wypożyczonych przedmiotów (lub
          zwrócenia przedmiotów brudnych pomimo niewniesienia opłaty za
          czyszczenie) kaucja zostanie pomniejszona zgodnie z kosztami
          czyszczenia i wartością każdego produktu. W przypadku braku uszkodzeń,
          kaucja zostanie zwrócona do 7 dni roboczych po otrzymaniu
          wypożyczanych przedmiotów i stwierdzeniu braku uszkodzeń.
        </li>
        <li>
          <p>
            Pełna kwota należna za wypożyczenie wraz z kaucją zwrotną zostanie
            przekazana Glamo.pl nie później niż 10 dni przed spodziewaną datą
            wysyłki lub odbioru, w gotówce lub przelewem na konto:
          </p>
          <p>
            Pracownia Florystyczna Cuda Kwiatki Karolina Struzik
            <br />
            ul. Kazimierzowska 18a
            <br />
            05-807 Owczarnia
          </p>
          <p>
            NIP: 534-238-16-63
            <br />
            REGON: 365805643
            <br />
            Nr konta Idea Bank S.A.:
            <br />
            Konto: 92 1950 0001 2006 0005 0693 0001
            <br />
            Numer rachunku IBAN: PL 92 1950 0001 2006 0005 0693 0001
            <br />
            Numer BIC (SWIFT): IEEAPLPA
          </p>
        </li>
        <li>
          <p>
            Za moment realizacji usługi wypożyczenia uznaje się moment
            dostarczenia przedmiotów Zamawiającemu.
          </p>
          <p>
            Jeśli zamówione przedmioty zostały dostarczone Zamawiającemu przed
            datą wydarzenia, nie ponosi on dodatkowych kosztów za dni
            przypadające między datą dostarczenia a datą wydarzenia.
          </p>
        </li>
      </ol>

      <ParagraphHeading>Warunki Umowy</ParagraphHeading>
      <ol>
        <li>
          Zamawiającym, którzy zawarli Umowę na odległość lub poza lokalem
          przedsiębiorstwa, przysługuje prawo do odstąpienia od Umowy w terminie
          14 dni od jej zawarcia, bez podawania przyczyny i bez ponoszenia
          kosztów (art. 27 ustawy z dnia 30 maja 2014 r. o prawach konsumenta;
          Dz.U. z 2014 r. poz. 827 ze zm.).
        </li>
        <li>
          W przypadku zrealizowania Umowy przed upływem 14 dni od jej podpisania
          Zamawiającemu przestaje przysługiwać prawo do odstąpienia bez
          podawania przyczyny z uwagi na wykonanie zamówionej w Umowie usługi.
        </li>
        <li>
          W przypadku rezygnacji z Umowy przez Zamawiającego po wpłacie zadatku
          nie jest on zwracany.
        </li>
        <li>
          W przypadku rezygnacji przez Zamawiającego w terminie do dwóch tygodni
          przed uroczystością, Zamawiający zobowiązany jest zapłacić karę umowną
          w wysokości 20% ogólnej wartości usługi. Kara może zostać pobrana z
          wpłaconej kaucji.
        </li>
        <li>
          W przypadku nieodebrania przesyłki lub dostawy przez Zamawiającego
          Umowa jest traktowana jako zerwana z winy Zamawiającego, co będzie
          skutkować naliczeniem kary umownej w wysokości 20% ogólnej wartości
          usługi.
        </li>
      </ol>

      <ParagraphHeading>Spory i reklamacje</ParagraphHeading>
      <ol>
        <li>
          Spory, które mogłyby wyniknąć podczas wykonywania prac strony będą
          rozstrzygać polubownie, a w przypadku niemożliwości porozumienia, za
          pośrednictwem właściwego Sądu Rejonowego.
        </li>
        <li>
          <p>
            Reklamację można złożyć w formie elektronicznej za pomocą maila lub
            odnośnika “Kontakt” dostępnego na stronie Serwisu lub w formie
            pisemnej.
          </p>
          <p>
            Reklamacje będą rozpatrywane w terminie do 30 dni od daty
            otrzymania.
          </p>
          <p>
            Odpowiedź na reklamację wysyłana jest wyłącznie na adres e-mail
            podany w Umowie.
          </p>
        </li>
        <li>
          Kolory produktów widoczne na ekranie mogą nieznacznie odbiegać od
          oryginału ze względu na różne kalibracje ekranów, a w przypadku zdjęć
          z realizacji również ze względu na oświetlenie na sali i styl obróbki
          fotografa.
        </li>
      </ol>

      <ParagraphHeading>Informacje uzupełniające</ParagraphHeading>
      <ol>
        <li>
          <p>
            Podanie danych osobowych, obejmujących imię, nazwisko, adres dostawy
            towaru, numer telefoniczny, adres e-mail Zamawiającego, jest
            dobrowolne, jednak konieczne w celu realizacji Zamówienia. Dane te
            mogą być przekazywane podmiotom współpracującym z Glamo.pl w celu
            realizacji Zamówień (np.: kurierzy).
          </p>
          <p>Administratorem danych osobowych jest:</p>
          <p>
            Pracownia Florystyczna Cuda Kwiatki Karolina Struzik
            <br />
            ul. Kazimierzowska 18a
            <br />
            05-807 Owczarnia
          </p>
          <p>
            NIP: 534-238-16-63
            <br />
            REGON: 365805643
          </p>
          <p>
            Zamawiającemu przysługuje prawo dostępu do treści swoich danych oraz
            prawo ich poprawienia lub usunięcia z bazy Glamo.pl. W powyższych
            kwestiach Zamawiający może kontaktować się z Glamo.pl przez adres
            e-mail: <span className="bold">{CONTACT_EMAIL_ADDRESS}</span>
          </p>
        </li>
        <li>
          <p>
            Szczegółowa polityka ochrony prywatności Glamo.pl stanowi integralną
            część Regulaminu i jest dołączana do niego w Załączniku, a także
            dostępna jest pod adresem{' '}
            <Link to="/polityka-prywatnosci">
              https://{SITE_HOSTNAME}/polityka-prywatnosci
            </Link>
            .
          </p>
        </li>
        <li>
          W sprawach nieuregulowanych niniejszą Umową będą miały zastosowanie
          przepisy Kodeksu Cywilnego.
        </li>
      </ol>

      <Text my="m">Aktualizacja: 10.01.2022r.</Text>
    </LayoutTextSection>
  </Layout>
)

export default Terms
